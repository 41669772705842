import React, { useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";
import MenuContext from "../../context/MenuContext";

const HomeMenu = ({ homeMenuSection, settings }) => {
  const { setActiveCategoryId, setIsProductCategoryOpen } =
    useContext(MenuContext);

  const productCategoryList = settings.data.body.filter(
    (item) => item.slice_type === "menu"
  );
  const [active, setActive] = useState(0);
  const activeServiceList = settings.data.body.filter((item) => {
    return (
      item.primary.service_category_label ===
      homeMenuSection[active].category_title
    );
  });

  return (
    <Container
      fluid
      className="home-menu-container px-0 d-flex flex-column flex-xl-row"
      id="home-menu"
    >
      <GatsbyImage
        image={homeMenuSection[active].category_image.gatsbyImageData}
        alt={homeMenuSection[active].category_image.alt || "nasco"}
        className="active-category-bg-image"
      />
      <div className="category-info py-5 ps-3 ps-sm-5 pe-5">
        <h2 className="pb-3">{homeMenuSection[active].category_title}</h2>
        <p className="pb-3">{homeMenuSection[active].category_description}</p>
        {homeMenuSection[active].link_label && (
          <Anchor
            href={homeMenuSection[active].link_to_category.url || "/"}
            className="category-btn d-flex align-items-center justify-content-between p-2 bg-white mb-4"
          >
            <h5 className="m-0 mx-3">{homeMenuSection[active].link_label}</h5>
            <StaticImage
              src="../../images/icons/redRightArrow.png"
              alt="Δείτε δείγμα εργασιών μας"
            />
          </Anchor>
        )}
        <div className="home-menu-items">
          {/* activeServiceList.length === 1 , show the category service */}
          {activeServiceList.length === 1 ? (
            activeServiceList[0].items.map((service) => {
              return (
                <Anchor
                  key={service.label}
                  className="d-flex justify-content-between"
                  href={service.link.url}
                >
                  <h4
                    className={`ps-3 mb-3 ${
                      service.stand_out_red && "text-primary"
                    } w-75`}
                  >
                    {service.label}
                  </h4>
                  <div className="mb-3 d-flex align-items-center service-arrow">
                    <StaticImage
                      src="../../images/icons/redRightArrowNoBG.png"
                      alt="red right arrow"
                    />
                  </div>
                </Anchor>
              );
            })
          ) : (
            <div className="d-flex flex-column align-items-start">
              {/* else show the product category list */}
              {productCategoryList.map((category) => {
                return (
                  <button
                    key={category.id}
                    type="button"
                    className="d-flex justify-content-between align-items-center w-100 mb-3"
                    onClick={() => {
                      setActiveCategoryId(category.id);
                      setIsProductCategoryOpen(true);
                    }}
                  >
                    <h4 className="ps-3">{category.primary.label}</h4>
                    <StaticImage
                      src="../../images/icons/redRightArrowNoBG.png"
                      alt="red right arrow"
                      className="category-menu-item-arrow"
                    />
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="category-card-container d-flex align-items-end px-4 py-5">
        {homeMenuSection.map((category, i) => {
          return (
            <button
              type="button"
              key={category.category_title}
              className="category-card p-3 bg-white"
              onClick={() => {
                setActive(i);
              }}
            >
              <GatsbyImage
                image={category.category_image.gatsbyImageData}
                className="category-card-image"
                alt={category.category_image.alt || "nasco"}
              />
              <h3 className="mt-3 text-start">{category.category_title}</h3>
            </button>
          );
        })}
      </div>
    </Container>
  );
};

export default HomeMenu;
