import React from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const Hero = ({ data }) => {
  return (
    <>
      {/* MOBILE */}
      <Container fluid className="mobile-hero-container p-0 d-lg-none">
        <GatsbyImage
          image={data.hero_image.gatsbyImageData}
          alt={data.hero_image.alt || "hero image"}
          className="hero-image"
        />
        <div className="hero-details  p-4 pb-5 bg-light">
          <h2 className="text-primary pb-3">{data.hero_title}</h2>
          <p>{data.hero_description}</p>
        </div>
        <Anchor className="hero-down-btn" href="#home-menu">
          <StaticImage
            src="../../images/icons/redDownArrow.png"
            alt="go to the next section arrow"
          />
        </Anchor>
      </Container>

      {/* DESKTOP */}
      <Container fluid className="hero-container d-none d-lg-block">
        <GatsbyImage
          image={data.hero_image.gatsbyImageData}
          alt={data.hero_image.alt || "hero image"}
          className="hero-image"
        />
        <div className="hero-details p-4 p-xxl-5">
          <h1 className="text-primary pb-xl-4">{data.hero_title}</h1>
          <p>{data.hero_description}</p>
          <Anchor className="hero-down-btn" href="#home-menu">
            <StaticImage
              src="../../images/icons/redDownArrow.png"
              alt="go to the next section arrow"
            />
          </Anchor>
        </div>
      </Container>
    </>
  );
};

export default Hero;
